import classNames from 'clsx';
import useComponentVisible from 'hooks/useComponentsVisible';
import { FC, useCallback } from 'react';
import { themeProvider } from 'theme';
import { GetDataServiceModel } from 'store/dto/GetDataServiceModel';
import { GetDataChannelModel } from 'store/dto/GetDataChannelModel';
import { Stack } from 'react-bootstrap';
import { ServicePopover } from './ServicePopover';

type Props = {
  service: GetDataServiceModel;
  channel: GetDataChannelModel;
  onEditService: (
    channel: GetDataChannelModel,
    service: GetDataServiceModel,
  ) => void;
};

export const ServiceFinanceTreeCell: FC<Props> = ({
  service,
  channel,
  onEditService,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const onClickInfo = useCallback(() => {
    setIsComponentVisible(() => !isComponentVisible);
  }, [setIsComponentVisible, isComponentVisible]);

  return (
    <Stack
      direction="horizontal"
      className={classNames('w-100 dx-g-bs5-table-tree-content')}
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 8,
        overflow: 'initial',
      }}
    >
      <span
        style={{
          border: '1px solid gray',
          display: 'flex',
          flex: 1,
          height: 48,
          padding: 4,
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
          borderRadius: 3,
          whiteSpace: 'initial',
        }}
      >
        {service.toolName}
      </span>
      <span
        style={{
          border: '1px solid gray',
          display: 'flex',
          flex: 1,
          height: 48,
          padding: 4,
          justifyContent: 'center',
          alignItems: 'start',
          gap: 10,
          fontSize: 10,
          borderRadius: 3,
          whiteSpace: 'initial',
          wordBreak: 'break-all',
          overflow: 'hidden',
        }}
      >
        {service.address}
      </span>
      <span
        style={{
          position: 'relative',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: 48,
            height: 48,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            fontSize: 10,
            border: '1px solid gray',
            borderRadius: 3,
            cursor: 'pointer',
            backgroundColor: isComponentVisible
              ? themeProvider.palette.lgreen
              : 'white',
            color: isComponentVisible ? 'white' : 'black',
          }}
          onClick={onClickInfo}
        >
          Инф.
        </div>
        {isComponentVisible && (
          <div
            style={{
              position: 'absolute',
              zIndex: '99999999999999',
              cursor: 'default',
              transform: 'translateX(5rem) translateY(-2.1rem)',
            }}
            ref={ref}
          >
            <ServicePopover
              service={service}
              channel={channel}
              onEditService={onEditService}
              onClickClose={() => setIsComponentVisible(false)}
            />
          </div>
        )}
      </span>
    </Stack>
  );
};
