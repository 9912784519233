import { FC, useMemo } from 'react';

import { FormikProps } from 'formik';
import { AsyncSelect, AsyncSelectProps } from '../inputs';

export interface FormikAsyncSelectProps
  extends Omit<AsyncSelectProps, 'onSelect'> {
  formik: FormikProps<any>;
  fieldName: string;
  cb?: (a: any) => void;
}

const FormikAsyncSelect: FC<FormikAsyncSelectProps> = ({
  formik,
  fieldName,
  cb,
  ...otherProps
}) => {
  const error = useMemo(
    () => (formik.touched[fieldName] ? formik.errors[fieldName] : ''),
    [fieldName, formik.errors, formik.touched],
  );

  return (
    <AsyncSelect
      value={formik.values[fieldName]}
      onSelect={(selectedOption) => {
        formik.setFieldValue(fieldName, selectedOption);
        if (cb) {
          cb(selectedOption);
        }
      }}
      error={error as string}
      onBlur={formik.handleBlur(fieldName)}
      {...otherProps}
    />
  );
};

export default FormikAsyncSelect;
