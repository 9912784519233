import React, { FC, SVGProps } from 'react';
import { COLORS } from 'theme';

const ServiceAdd: FC<SVGProps<SVGElement>> = ({ color = COLORS.black }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4V4C8.22876 4 6.34315 4 5.17157 5.17157C4 6.34315 4 8.22876 4 12V12C4 15.7712 4 17.6569 5.17157 18.8284C6.34315 20 8.22876 20 12 20V20C15.7712 20 17.6569 20 18.8284 18.8284C20 17.6569 20 15.7712 20 12V12"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M9 10L15 10"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 14H12"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 8L19 2M16 5H22"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ServiceAdd;
