import { useGetDataFilter } from 'hooks/useGetDataFilter';
import { Form } from 'react-bootstrap';
import { useCitiesQuery } from 'store/services/cities';
import { usePboAllQuery } from 'store/services/pbo';

export const FilterBar = () => {
  const { data: cities = [] } = useCitiesQuery();
  const { data: pboResponse } = usePboAllQuery({ Size: 50, Offset: 0 });

  const { city, setCity, pboType, setPboType } = useGetDataFilter();

  const pbos = pboResponse ? pboResponse.data : [];

  return (
    <div
      style={{
        paddingLeft: 30,
        paddingRight: 30,
        display: 'flex',
        flexDirection: 'row',
        gap: 30,
      }}
    >
      <div style={{ width: 400 }}>
        <Form.Group controlId="filterbarcities">
          <Form.Label>Город</Form.Label>
          <Form.Select
            aria-label="Город"
            defaultValue={city}
            onChange={({ target: { value } }) => {
              setCity(value || undefined);
            }}
          >
            <option value="">Выберите...</option>
            {cities.map((c, i) => (
              <option key={`filterbar-city-${i}`} value={c}>
                {c}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
      <div style={{ width: 400 }}>
        <Form.Group controlId="filterbarpbotypes">
          <Form.Label>Тип ПБО</Form.Label>
          <Form.Select
            aria-label="Тип ПБО"
            defaultValue={pboType}
            onChange={({ target: { value } }) => {
              return setPboType(value ? Number.parseInt(value, 10) : undefined);
            }}
          >
            <option value="">Выберите...</option>
            {pbos.map(({ id, name }, i) => (
              <option key={`filterbar-pbotype-${i}`} value={id.toString()}>
                {name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
    </div>
  );
};
