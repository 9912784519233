import React, { FC, useState, ReactNode, useEffect } from 'react';
import { Divider } from 'components';
import {
  ButtonGroup,
  Col,
  Form,
  ToggleButton,
  Button,
  Dropdown,
} from 'react-bootstrap';
import { useGetDataFilter } from 'hooks/useGetDataFilter';
import { months } from 'lib/enums/Month';
import { useDispatch } from 'react-redux';
import { expandAll, setExpandedRows } from 'store/features/expandedRowsSlice';
import { useSwitchableGetData } from 'hooks/useSwitchableGetData';
import { FilialType } from 'lib/enums/FilialType';
import { useNavigate } from 'react-router-dom';
import { ListIcon } from 'components/icons/List';
import { FilterIcon } from 'components/icons/Filter';
import { cleanObject } from 'lib/cleanObject';
import { FilterBar } from './FilterBar';
import { MonthTable } from './MonthTable';
import { YearTable } from './YearTable';

type FlexRowProps = {
  children?: ReactNode;
  style?: Record<string, any>;
};

const FlexRow = ({ children, style }: FlexRowProps) => (
  <div style={{ display: 'flex', flexWrap: 'wrap', ...style }}>{children}</div>
);

export const TableContext = React.createContext({ tableView: '' });

const HomePage: FC = () => {
  const [tableView, setTableView] = useState<'year_table' | 'month_table'>(
    'year_table',
  );
  const {
    month,
    setMonth,
    year,
    setYear,
    showFederalData,
    setShowFederalData,
    city,
    pboType,
  } = useGetDataFilter();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterVisible, setFilterVisible] = useState(false);

  const { data = [] } = useSwitchableGetData(
    cleanObject({ year, city, pboTypeId: pboType }),
  );

  useEffect(() => {
    if (filterVisible) {
      dispatch(expandAll({ data, expandRowsType: FilialType.ПБО }));
    }
  }, [data]);

  return (
    <TableContext.Provider value={{ tableView }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 114px)',
          paddingTop: 16,
        }}
      >
        <FlexRow
          style={{
            marginTop: '16px',
            gap: '10px',
            padding: '0 10px',
          }}
        >
          <div
            style={{
              flexGrow: 1,
            }}
          >
            {!showFederalData && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 10,
                }}
              >
                <Button
                  variant="outline-success"
                  onClick={() => {
                    dispatch(
                      expandAll({
                        data,
                        expandRowsType: FilialType.МПП,
                      }),
                    );
                  }}
                  style={{
                    padding: 4,
                    width: '160px',
                    justifyContent: 'center',
                  }}
                >
                  - Свернуть
                </Button>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-success"
                    id="dropdown-expand"
                    style={{
                      padding: 4,
                      width: '160px',
                      justifyContent: 'center',
                    }}
                  >
                    + Развернуть
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(
                          expandAll({ data, expandRowsType: FilialType.МПП }),
                        );
                      }}
                    >
                      Все МПП
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(
                          expandAll({ data, expandRowsType: FilialType.КПП }),
                        );
                      }}
                    >
                      Все КПП
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(
                          expandAll({ data, expandRowsType: FilialType.ПБО }),
                        );
                      }}
                    >
                      Все ПБО
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(expandAll({ data, expandRowsType: 'all' }));
                      }}
                    >
                      Всю таблицу
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>

          <div>
            <Button
              variant="outline-success"
              onClick={() => navigate('projects')}
              style={{ minWidth: '132px' }}
            >
              <ListIcon /> Проекты
            </Button>
          </div>
          <div>
            <ButtonGroup>
              <ToggleButton
                style={{ minWidth: '132px' }}
                type="radio"
                id="99999"
                variant="outline-success"
                value="99999"
                checked={showFederalData}
                onChange={() => {
                  setShowFederalData(true);
                  setTableView('year_table');
                  setFilterVisible((val) => false);
                }}
              >
                99999
              </ToggleButton>
              <ToggleButton
                style={{ minWidth: '132px' }}
                type="radio"
                id="ПБО"
                variant="outline-success"
                value="ПБО"
                checked={!showFederalData}
                onChange={() => setShowFederalData(false)}
              >
                ПБО
              </ToggleButton>
            </ButtonGroup>
          </div>
          <div>
            <div style={{ height: 33, border: '1px solid gray', width: 1 }} />
          </div>
          <div>
            <ButtonGroup>
              <ToggleButton
                style={{ minWidth: '132px' }}
                type="radio"
                id="year_table"
                variant="outline-success"
                value="year_table"
                checked={tableView === 'year_table'}
                onChange={() => setTableView('year_table')}
              >
                Год
              </ToggleButton>
              <ToggleButton
                style={{ minWidth: '132px' }}
                type="radio"
                id="month_table"
                variant="outline-success"
                value="month_table"
                disabled={showFederalData}
                checked={tableView === 'month_table'}
                onChange={() => setTableView('month_table')}
              >
                Месяц
              </ToggleButton>
            </ButtonGroup>
          </div>
          <div>
            <Form.Select
              style={{ padding: '.260rem 2.25rem .260rem .75rem' }}
              defaultValue={year}
              onChange={({ target: { value } }) =>
                setYear(Number.parseInt(value, 10))
              }
            >
              {[2023, 2024, 2025].map((y) => (
                <option value={y} key={y}>
                  {y}
                </option>
              ))}
            </Form.Select>
          </div>
          {tableView === 'month_table' && (
            <Col>
              <Form.Select
                style={{ padding: '.260rem 2.25rem .260rem .75rem' }}
                defaultValue={month}
                onChange={({ target: { value } }) =>
                  setMonth(Number.parseInt(value, 10))
                }
              >
                {months.map((m, i) => (
                  <option value={(i + 1).toString()}>{m}</option>
                ))}
              </Form.Select>
            </Col>
          )}
          {!showFederalData && (
            <div>
              <Button
                variant={filterVisible ? 'success' : 'outline-success'}
                style={{ paddingLeft: 10, paddingRight: 10 }}
                onClick={() => setFilterVisible((val) => !val)}
              >
                <FilterIcon /> Фильтры
              </Button>
            </div>
          )}
        </FlexRow>
        <Divider style={{ marginBottom: 0 }} />
        {filterVisible && (
          <div style={{ marginTop: '12px' }}>
            <FilterBar />
            <Divider style={{ marginBottom: 0 }} />
          </div>
        )}
        {tableView === 'year_table' ? <YearTable /> : <MonthTable />}
      </div>
    </TableContext.Provider>
  );
};

export default HomePage;
