import { FilialType } from 'lib/enums/FilialType';
import { FilialModel } from 'store/dto/FilialModel';

export const formatFilialNameFromRow = (r: FilialModel) => {
  if (r.filialTypeId === FilialType.ПБО) {
    return r.filialCode
      ? `${r.filialCode}-${r.filialName}, ${r.filialCity}, ${r.filialAddress}`
      : `${r.filialName}, ${r.filialCity}, ${r.filialAddress}`;
  }

  return `${r.filialName} - ${r.filialManagerSurname || ''} ${
    r.filialManagerName || ''
  } ${r.filialManagerPatronymic || ''}`;
};
