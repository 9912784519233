export const roundPercent = (percent: number | string) => {
  const num = Number(percent);

  const isShort = process.env.REACT_APP_SHORT_NUM;

  if (num) {
    if (isShort) {
      const length = num?.toFixed(0).length;
      const clearNum = Number(num?.toFixed(0));
      const isPositive = Boolean(num > 0);

      if (length < 4) {
        return num.toFixed(1).replaceAll('.', ',');
      }

      if (isPositive) {
        if (length === 4 || length === 5 || length === 6) {
          const trim = Math.floor(clearNum / 1e3);
          return `${trim} тыс.`;
        }

        if (length === 7 || length === 8 || length === 9) {
          const trim = Math.floor(clearNum / 1e6);
          return `${trim} млн.`;
        }

        if (length === 10 || length === 11 || length === 12) {
          const trim = Math.floor(clearNum / 1e9);
          return `${trim} млрд.`;
        }

        if (length === 13 || length === 14 || length === 15) {
          const trim = Math.floor(clearNum / 1e12);
          return `${trim} трлн.`;
        }

        if (length === 16 || length === 17 || length === 18) {
          const trim = Math.floor(clearNum / 1e15);
          return `${trim} квадрлн.`;
        }

        return num.toFixed(1);

        // eslint-disable-next-line no-else-return
      } else {
        if (length === 3 || length === 4 || length === 5) {
          const trim = Math.ceil(clearNum / 1e3);
          return `${trim} тыс.`;
        }

        if (length === 6 || length === 7 || length === 8) {
          const trim = Math.ceil(clearNum / 1e6);
          return `${trim} млн.`;
        }

        if (length === 9 || length === 10 || length === 11) {
          const trim = Math.ceil(clearNum / 1e9);
          return `${trim} млрд.`;
        }

        if (length === 12 || length === 13 || length === 14) {
          const trim = Math.ceil(clearNum / 1e12);
          return `${trim} трлн.`;
        }

        if (length === 15 || length === 16 || length === 17) {
          const trim = Math.ceil(clearNum / 1e15);
          return `${trim} квадрлн.`;
        }

        return num.toFixed(1);
      }
    }

    return num.toFixed(1).replaceAll('.', ',');
  }
  return 0;
};
