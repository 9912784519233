export function cleanObject<T extends Record<string, any>>(obj: T): T {
  const keys = Object.keys(obj);
  const newObj: T = {} as T;

  keys.forEach((k) => {
    if (obj[k] !== undefined) {
      // @ts-ignore
      newObj[k] = obj[k];
    }
  });

  return newObj;
}
