import React, { FC, useCallback, useRef, useState } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import { useNumericFormat } from 'react-number-format';

export const NumberInput: FC<FormControlProps> = (props) => {
  const numericFormatProps = useNumericFormat({
    ...(props as any),
    decimalSeparator: ',',
    thousandSeparator: ' ',
  });

  const { onChange } = props;

  const inputRef = useRef<any>(null);

  const [selection, setSelection] = useState();

  const onChangeCallback = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.target.value = numericFormatProps.removeFormatting!(e.target.value);

      // setSelection(e.target.selectionStart);

      if (onChange) {
        onChange(e);
      }
      return null;
    },
    [numericFormatProps, onChange, setSelection],
  );

  // useEffect(() => {
  //   inputRef.current.focus();
  //   selection !== undefined && inputRef.current.setSelectionRange(selection, selection);
  // }, [props.value]);

  return (
    <Form.Control
      ref={inputRef}
      type="text"
      {...props}
      value={numericFormatProps.value as string}
      onChange={onChangeCallback}
      // onSelect={(e: any) => {
      //   setSelection(e.target.selectionStart);
      // }}
    />
  );
};
