import {
  FilialRequest,
  useGetDataQuery,
  useGetFederalDataQuery,
} from 'store/services/filials';
import { useMemo } from 'react';
import { useGetDataFilter } from './useGetDataFilter';

export const useSwitchableGetData = (params: FilialRequest) => {
  const { showFederalData } = useGetDataFilter();
  const useGetData = useMemo(
    () => (showFederalData ? useGetFederalDataQuery : useGetDataQuery),
    [showFederalData],
  );

  return useGetData(params);
};
