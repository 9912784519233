import { ReactNode } from 'react';

const FractionDigits = 2;

type getWrapProp = {
  num: number;
  trim: number;
  text: string;
};

const getLocale = (num: number) =>
  num
    .toLocaleString('ru-RU', {
      minimumFractionDigits: FractionDigits,
      maximumFractionDigits: FractionDigits,
    })
    .replace(',00', '');

type getWrapType = (props: getWrapProp) => ReactNode;

const getWrap: getWrapType = ({ num, trim, text }) => (
  <span title={`${getLocale(num)} р`}>
    {trim} {text}
  </span>
);

const numToRub = (num: number) => {
  if (num) {
    const isShort = process.env.REACT_APP_SHORT_NUM;

    if (isShort) {
      const length = num?.toFixed(0).length;
      const clearNum = Number(num?.toFixed(0));
      const isPositive = Boolean(num > 0);

      if (length < 4) {
        return getLocale(num);
      }

      if (isPositive) {
        if (length === 4 || length === 5 || length === 6) {
          const trim = Math.floor(clearNum / 1e3);
          return getWrap({ num, trim, text: 'тыс.' });
        }

        if (length === 7 || length === 8 || length === 9) {
          const trim = Math.floor(clearNum / 1e6);
          return getWrap({ num, trim, text: 'млн.' });
        }

        if (length === 10 || length === 11 || length === 12) {
          const trim = Math.floor(clearNum / 1e9);
          return getWrap({ num, trim, text: 'млрд.' });
        }

        if (length === 13 || length === 14 || length === 15) {
          const trim = Math.floor(clearNum / 1e12);
          return getWrap({ num, trim, text: 'трлн.' });
        }

        if (length === 16 || length === 17 || length === 18) {
          const trim = Math.floor(clearNum / 1e15);
          return getWrap({ num, trim, text: 'квадрлн.' });
        }

        return getLocale(num);

        // eslint-disable-next-line no-else-return
      } else {
        if (length === 5 || length === 6 || length === 7) {
          const trim = Math.ceil(clearNum / 1e3);
          return getWrap({ num, trim, text: 'тыс.' });
        }

        if (length === 8 || length === 9 || length === 10) {
          const trim = Math.ceil(clearNum / 1e6);
          return getWrap({ num, trim, text: 'млн.' });
        }

        if (length === 11 || length === 12 || length === 13) {
          const trim = Math.ceil(clearNum / 1e9);
          return getWrap({ num, trim, text: 'млрд.' });
        }

        if (length === 14 || length === 15 || length === 16) {
          const trim = Math.ceil(clearNum / 1e12);
          return getWrap({ num, trim, text: 'трлн.' });
        }

        if (length === 17 || length === 18 || length === 19) {
          const trim = Math.ceil(clearNum / 1e15);
          return getWrap({ num, trim, text: 'квадрлн.' });
        }

        return getLocale(num);
      }
    }
    return getLocale(num);
  }
  return 0;
};

export default numToRub;
