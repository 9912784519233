import { FC, useEffect } from 'react';

type Props = {
  children: JSX.Element;
  onClickOutside: () => void;
  onContextMenu: () => void;
};

export const ContextMenuContainer: FC<Props> = ({
  children,
  onClickOutside,
  onContextMenu,
}) => {
  useEffect(() => {
    document.addEventListener('click', onClickOutside);

    if (onContextMenu) {
      document.addEventListener('contextmenu', onContextMenu);
    }
    return () => {
      document.removeEventListener('click', onClickOutside);

      if (onContextMenu) {
        document.removeEventListener('contextmenu', onContextMenu);
      }
    };
  }, []);

  return children;
};
