import { FC, ComponentElement } from 'react';
import classNames from 'clsx';
import SortUp from 'components/icons/SortUp';
import SortDown from 'components/icons/SortDown';
import { Stack } from 'react-bootstrap';

type Props = {
  visible: boolean;
  expanded: boolean;
  onToggle: () => void;
  content: any;
  value: any;
  tools: ComponentElement<any, any>[];
  isChannel?: boolean;
};

export const ChannelTreeNode: FC<Props> = ({
  visible,
  expanded,
  onToggle,
  content,
  value,
  tools,
  isChannel = false,
}) => (
  <Stack
    direction="horizontal"
    className={classNames('w-100 dx-g-bs5-table-tree-content')}
    style={{
      border: '1px solid gray',
      borderRadius: 3,
      padding: 0,
      backgroundColor: isChannel ? '#F5F5F5' : 'rgba(76, 163, 110, 0.15)',
    }}
  >
    {visible ? (
      expanded ? (
        <span
          onClick={onToggle}
          style={{
            cursor: 'pointer',
            paddingTop: 11,
            paddingBottom: 11,
            paddingLeft: 10,
          }}
        >
          <SortUp />
        </span>
      ) : (
        <span
          onClick={onToggle}
          style={{
            cursor: 'pointer',
            paddingTop: 11,
            paddingBottom: 11,
            paddingLeft: 10,
          }}
        >
          <SortDown />
        </span>
      )
    ) : null}
    <span
      className="me-auto"
      style={{
        whiteSpace: 'initial',
        textAlign: 'left',
        paddingLeft: 10,
        paddingRight: 10,
        minHeight: 48,
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {content || value}
    </span>
    <span
      style={{
        paddingTop: 11,
        paddingBottom: 11,
        paddingRight: 10,
      }}
    >
      {tools}
    </span>
  </Stack>
);

export const ChannelTreeNodeTool = ({
  onClick,
  icon,
}: {
  onClick: () => void;
  icon: ComponentElement<any, any>;
}) => (
  <span onClick={onClick} style={{ cursor: 'pointer' }}>
    {icon}
  </span>
);
