import { FC, SVGProps } from 'react';

const Logo: FC<SVGProps<SVGElement>> = (props) => (
  <svg
    width="58"
    height="46"
    viewBox="0 0 58 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.2092 0.495431C32.7115 3.6165 31.2902 9.7195 34.0733 14.5452L51.7809 45.244C52.0244 45.6615 52.5711 45.7758 52.9637 45.5025C57.4615 42.3814 58.8878 36.2784 56.0997 31.4527L38.3921 0.753873C38.1535 0.331435 37.6068 0.217119 37.2092 0.495431Z"
      fill="#EE7409"
    />
    <path
      d="M19.5067 0.495431C15.009 3.6165 13.5876 9.7195 16.3707 14.5452L34.0783 45.244C34.3219 45.6615 34.8685 45.7758 35.2612 45.5025C39.7589 42.3814 41.1852 36.2784 38.3971 31.4527L20.6895 0.753873C20.4509 0.331435 19.8993 0.217119 19.5067 0.495431Z"
      fill="#EE7409"
    />
    <path
      d="M10.6007 24.4551C4.74625 24.4551 0 29.2013 0 35.0558C0 40.9103 4.74625 45.6565 10.6007 45.6565C16.4552 45.6565 21.2014 40.9103 21.2014 35.0558C21.2014 29.2013 16.4552 24.4551 10.6007 24.4551Z"
      fill="#E94A2A"
    />
  </svg>
);

export default Logo;
